import Video1 from './images/video/video1.mp4';
import movbbb from './images/video/mov_bbb.mp4';




const Video = {
    Video1,
    movbbb,
}

export default Video;